<template>
  <v-app>
    <v-app-bar app color="primary">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>希革马AI后台管理系统</v-toolbar-title>
    </v-app-bar>

    <Navbar />

    <MainContent />
  </v-app>
</template>

<script>
import Navbar from '@/components/Navbar.vue';
import MainContent from '@/components/MainContent.vue';
export default {
  components: {
    Navbar,
    MainContent,
  },
  data() {
    return {
      drawer: false,
    };
  },
  mounted() {
    this.checkPhone();
    this.checkKey();
  },
  methods: {
    checkKey() {
      let key = sessionStorage.getItem('key');
      if (key == null || key == '') {
        confirm('非法登录');
        window.location.replace('about:blank');
      }
    },
    checkPhone() {
      console.log(this.isMobile())
      if (this.isMobile()) {
        confirm('建议使用PC端进行后端管理系统的访问!');
        window.location.replace('about:blank');
      }
    },
    isMobile() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      // Windows Phone 必须在第一位，因为 IE11 的 UA 中也含有 Phone
      return /Windows Phone|SymbianOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Android/.test(userAgent);
    }
  }
};
</script>

<style scoped>
.v-main {
  padding: 0% !important;
}
</style>