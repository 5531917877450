<template>
  <v-navigation-drawer app permanent>
    <v-list dense>
      <v-list-item link to="/admin">
        <v-list-item-icon>
          <v-icon>mdi-view-dashboard</v-icon>
        </v-list-item-icon>
        <v-list-item-title>后台功能菜单</v-list-item-title>
      </v-list-item>
      <v-list-item link to="/userManager">
        <v-list-item-icon>
          <v-icon>mdi-account-group</v-icon>
        </v-list-item-icon>
        <v-list-item-title>用户管理</v-list-item-title>
      </v-list-item>
      <v-list-item link to="/bannerManager">
        <v-list-item-icon>
          <v-icon>mdi-image-filter-center-focus</v-icon> <!-- 图标改为图片相关的图标 -->
        </v-list-item-icon>
        <v-list-item-title>轮播图管理</v-list-item-title>
      </v-list-item>
      <v-list-item link to="/articleManager">
        <v-list-item-icon>
          <v-icon>mdi-book-open-page-variant</v-icon> <!-- 图标改为书籍/文章相关的图标 -->
        </v-list-item-icon>
        <v-list-item-title>AI教育文章管理</v-list-item-title>
      </v-list-item>
      <v-list-item link to="/sysManager"> <!-- 链接地址根据实际路由调整 -->
        <v-list-item-icon>
          <v-icon>mdi-key</v-icon> <!-- 图标改为钥匙相关的图标 -->
        </v-list-item-icon>
        <v-list-item-title>GPT密钥设置</v-list-item-title>
      </v-list-item>
      <!-- Add more navigation items as needed -->
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'Navbar',
};

</script>