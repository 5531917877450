<template>
    <v-main>
      <v-container>
        <router-view></router-view>
      </v-container>
    </v-main>
  </template>
  
  <script>
  export default {
    name: 'MainContent',
  };
  </script>
  <style>
  .v-main {
    padding: 0px 0px 0px 256px !important;
  }
</style>
  